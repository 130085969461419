<template>
  <div>
    <div
      class="d-sm-flex d-block w-100 text-center px-5 py-2 justify-content-end"
    >
      <div class="border-left pl-2">
        <small class="text-secondary ml-2" style="letter-spacing: 2px"
          >العراق، بغداد</small
        >
        <b-icon icon="geo-alt-fill" variant="info" scale="1"> </b-icon>
      </div>
      <div class="border-left px-2">
        <small class="text-secondary ml-2" style="letter-spacing: 2px"
          ><a href="tel:009647714472214">009647714472214</a></small
        >
        <b-icon icon="telephone" variant="info" scale="1"> </b-icon>
      </div>
      <div class="pr-2">
        <small class="text-secondary ml-2" style="letter-spacing: 2px"
          ><a href="mailto:info@masrafna.com.iq">info@masrafna.com.iq</a></small
        >
        <b-icon icon="mailbox" variant="info" scale="1"> </b-icon>
      </div>
    </div>
    <!-- Navbar -->
    <b-navbar toggleable="lg" class="px-5 py-2">
      <b-navbar-brand class="d-flex align-items-center mr-0">
        <img src="@/assets/logo.svg" />
        <h4 class="text-secondary mb-0 mx-2">مصرفنا</h4>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="justify-content-end">
        <b-navbar-nav v-for="(item, index) in list" :key="index" right>
          <b-nav-item>
            <router-link :to="item.to" exact> {{ item.name }} </router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <section
      v-if="currentRouteName !== '/'"
      class="d-flex align-items-center p-5 gradient-banner position-relative"
    >
      <div class="shapes-container">
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="1500"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-up-right"
          data-aos-duration="1000"
          data-aos-delay="200"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
        <div
          class="shape"
          data-aos="fade-down-left"
          data-aos-duration="1000"
          data-aos-delay="100"
        ></div>
      </div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 order-2 order-md-1 text-center text-md-right">
            <h5 class="text-white font-weight-bold my-3">
              {{ historyRouteName() }} {{ currentRouteName }}
            </h5>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "الصفحة الرئيسية",
          to: "/",
        },
        {
          name: "عن مصرفنا",
          to: "/about",
        },
        {
          name: "الخدمات",
          to: "/service",
        },
        {
          name: "المصارف",
          to: "/banks",
        },
        {
          name: "المقالات",
          to: "/articles",
        },
        {
          name: "الأخبار",
          to: "/news",
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    historyRouteName() {
      var routeName = "";
      this.$router.history.current.matched.forEach((element, index) => {
        if (index !== this.$router.history.current.matched.length - 1)
          routeName += element.name + " > ";
      });
      return routeName;
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  form.form-inline {
    display: flex !important;
    flex-flow: nowrap !important;
  }
}
.nav-link {
  color: #3a4079 !important;
  font-weight: normal;
  letter-spacing: 1px;
}
.nav-link a:hover {
  color: #1797e8;
  border-bottom: 2px solid #1797e8 !important;
  text-decoration: none;
}
.router-link-active {
  color: #1797e8;
  border-bottom: 2px solid #1797e8 !important;
}
.gradient-banner {
  height: 30vh;
  &::before {
    border-radius: 0%;
  }
}
</style>
