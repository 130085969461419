<template>
  <b-container id="website" fluid class="p-0">
    <Nav></Nav>
    <div style="margin-bottom: 7rem !important">
      <div v-if="hasRequest" class="req-loader">
        <img src="@/assets/logo.svg" width="120" alt="loader_logo" />
      </div>
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </b-container>
</template>

<script>
import Nav from "./components/Nav";
import Footer from "./components/Footer";

export default {
  components: {
    Nav,
    Footer,
  },
  computed: {
    hasRequest() {
      return this.$store.state.loader.requests.length;
    },
  },
};
</script>

<style lang="scss">
.text-grey {
  color: #808080 !important;
}
</style>
