<template>
  <footer>
    <div class="footer-main">
      <div class="container">
        <b-row>
          <b-col lg="2" md="2" class="col-6 mt-5 mt-lg-0">
            <div class="block-2">
              <h6>تعريف عن مصرفنا</h6>
              <p>
                دليلك الشامل والمتكامل لكل المصارف العراقية والخدمات الرئيسية
                المقدمة من المصارف للأفراد والشركات واحدث الاخبار المالية
              </p>
            </div>
          </b-col>
          <b-col lg="2" md="2" class="col-6 mt-5 mt-lg-0">
            <div class="block-2">
              <h6>الخدمات الرئيسية</h6>
              <ul v-for="(item, index) in homeLinks" :key="index">
                <li>
                  <router-link :to="item.to" exact>{{ item.name }}</router-link>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col lg="2" md="2" class="col-6 mt-5 mt-lg-0">
            <div class="block-2">
              <h6>المصارف</h6>
              <ul v-for="item in banks.slice(0, 5)" :key="item.id">
                <li>
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </b-col>
          <b-col lg="2" md="2" class="col-6 mt-5 mt-lg-0">
            <div class="block-2">
              <h6>أخر الأخبار</h6>
              <ul v-for="(item, index) in newsLinks" :key="index">
                <li>
                  <router-link :to="item.to" exact>{{ item.name }}</router-link>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col lg="3" md="12" class="m-md-auto align-self-center">
            <div class="d-flex flex-column align-items-start">
              <h6 class="text-light">أتصل بنا</h6>
              <p>
                رقم الهاتف:
                <a href="tel:009647714472214" style="text-direction: rtl"
                  >009647714472214</a
                >
              </p>
              <p>
                البريد الألكتروني:
                <a href="mailto:info@masrafna.com.iq">info@masrafna.com.iq</a>
              </p>
              <p>العنوان: بغداد – كرادة خارج – حي بابل – شارع 30 - مبنى 24</p>
              <h6 class="text-light">وسائل التواصل</h6>

              <!-- <ul
                class="d-flex flex-row mr-3 my-4 p-0"
                style="list-style: none"
              >
                <li class="ml-5">
                  <b-icon icon="facebook" variant="light" scale="2">
                    <a></a
                  ></b-icon>
                </li>
                <li class="ml-5">
                  <b-icon icon="youtube" variant="light" scale="2">
                    <a></a>
                  </b-icon>
                </li>
                <li class="ml-5">
                  <b-icon icon="instagram" variant="light" scale="2">
                    <a></a>
                  </b-icon>
                </li>
              </ul> -->
              <ul class="d-flex flex-row p-0" style="list-style: none">
                <li class="ml-3">
                  <a
                    href="https://apps.apple.com/iq/app/masrafna-%D9%85%D8%B5%D8%B1%D9%81%D9%86%D8%A7/id1664131687"
                    ><img
                      src="@/assets/apple-store.jpg"
                      width="120"
                      alt="Apple Store"
                  /></a>
                </li>
                <li class="mx-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.masrafna"
                    ><img
                      src="@/assets/google-play.jpg"
                      width="120"
                      alt="Google Play"
                  /></a>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="text-center bg-dark py-4">
      <h6 class="text-muted">
        حقوق النشر محفوظة &copy; {{ getYear() }}
        <span class="text-light">مصرفنا</span>
      </h6>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      newsLinks: [
        { name: "الأخبار", to: "/news" },
        { name: "المقالات", to: "/articles" },
      ],
      homeLinks: [
        { name: "الخدمات", to: "/service" },
        { name: "المصارف", to: "/banks" },
      ],
      banksList: [],
    };
  },
  mounted() {
    this.$store.dispatch("website/banks/get", 1);
  },
  computed: {
    banks() {
      return this.$store.state.website.banks.list;
    },
  },
  methods: {
    getYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style scoped>
.footer-main {
  padding: 4rem 0;
  background: #1a1b1f;
}

@media (max-width: 768px) {
  .footer-main {
    padding: 2rem 0;
  }
}

.footer-main .block-2 h6 {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 25px;
}

.footer-main .block-2 ul {
  padding: 0;
}

.footer-main .block-2 ul li {
  margin-bottom: 10px;
  list-style: none;
}

.footer-main .block-2 ul li,
a,
p {
  font-size: 14px;
  color: #6f6f71;
  transition: 0.2s ease;
}

.footer-main .block-2 ul li a:hover {
  color: #fff;
  text-decoration: none;
}
a,
img,
.b-icon {
  cursor: pointer;
}
</style>
